<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="[
      itemsLoading ? 'data-table--loading' : '',
      canBeSelected ? 'data-table--selectable' : '',
    ]"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :item-class="itemClass"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <logs-table-item-skeleton-mobile v-for="item in 5" :key="item"/>
        </template>
        <template v-else>
          <logs-table-sort-skeleton/>
          <logs-table-skeleton-item/>
        </template>
      </template>

      <template v-slot:no-data>
        <slot name="no-data"/>
      </template>
      <template v-slot:no-results>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="$emit('clearFilters')"
          :searchTerm="searchTerm"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.mobile && items.length"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          v-if="!itemsLoading"
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <slot name="actions" v-bind="item"></slot>
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        <a
          class="font-weight-bold"
          target="__blank"
          :href="`https://www.${item.name}`"
        >
          {{ item.name }}
        </a>
      </template>

      <template v-slot:item.proxied="{item}">
        <div class="text-center">
          <v-progress-circular
            v-if="item.proxiable && item.loading"
            indeterminate
            size="24"
            :color="item.proxied ? 'primary' : 'gray'"
          />
          <v-tooltip 
            v-else-if="item.proxiable && item.proxied"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                color="primary"
                elevation="0"
                :class="'btn-proxy-status'"
                @click="$emit('toggle-proxy-status', item)"
              >
                <v-icon size="35">$proxyOn</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.click.disable') }}</span>
          </v-tooltip>
          <v-tooltip 
            v-else-if="item.proxiable"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              color="gray"
              elevation="0"
              :class="'btn-proxy-status btn-proxy-status--off'"
              @click="$emit('toggle-proxy-status', item)"
              >
              <v-icon size="30">$proxyOff</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.click.enable') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.mobile"
        v-slot:item="{ item, headers }"
      >
        <tr>
          <td class="w-100">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <slot name="mobile" v-bind="{ item, headers }"/>

                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.actions') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <slot name="actions"/>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer="{ props }" v-if="items.length">
        <div>
          <data-iterator-footer
            :page="props.pagination.page"
            :numberOfPages="props.pagination.pageCount"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="props.pagination.itemsPerPage"
            v-on="$listeners"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";
import LogsTableSkeletonItem from "./LogsTableSkeletonItem.vue";
import LogsTableSortSkeleton from "./LogsTableSortSkeleton.vue";
import LogsTableItemSkeletonMobile from "./LogsTableItemSkeletonMobile.vue";
import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    LogsTableSkeletonItem,
    LogsTableSortSkeleton,
    LogsTableItemSkeletonMobile,
    DataIteratorNoResultsContainer,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
    itemClass: Function,
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 8px;
}
.btn-proxy-status{
  height: 36px!important;
  &--off{
    &:before{
      background-color: var(--v-gray-darken1);
    }
  }
}

.params {
  max-width: 450px;
}

a {
  color: map-get($text, headings);
  transition: color 0.24s ease;

  &:hover {
    color: map-get($primary, base);
  }
}

.data-table .actions-row {
  justify-content: flex-start;
  gap: 12px;
}

.data-table .actions-row:last-child {
  justify-content: flex-end;
}

.custom-sort::v-deep {
  .sort-item:last-child {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 56px;
  }
}
</style>
