<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="
          $vuetify.breakpoint.width <= 1400 ? 'flex-column flex-nowrap' : ''
        "
      >
        <v-col
          class="d-flex align-center"
          :style="
            $vuetify.breakpoint.width <= 1400
              ? ''
              : 'min-width: calc(100% - 482px); max-width: calc(100% - 482px)'
          "
        >
          <h4 class="font-weight-light text-gray" style="white-space: nowrap">
            {{ $t('heading.hosting.dns.title') }}
            <active-server-account-selector
              v-if="service && service.dns_server_internal && service.server_accounts.length > 1"
              class="ml-4"
              :value.sync="serverAccount"
              :items="service.server_accounts"
            />
          </h4>
          <v-select
            class="ml-2 dns-zone"
            outlined
            style="max-width: calc(100% - 102px)"
            :hide-details="true"
            v-model="zone"
            :items="zones.items"
            item-text="name"
            item-value="name"
            :loading="zones.loading"
            @change="reloadZoneRecords"
            return-object
            :menu-props="{
              offsetY: true,
              nudgeBottom: '8px',
            }"
            v-if="!records.loading"
          />
        </v-col>
        <v-col
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
          :style="$vuetify.breakpoint.width <= 1400 ? '' : 'min-width: 482px'"
        >
          <data-iterator-filter-controls
            v-if="!records.loading"
            :showSearchTerm="true"
            :searchTerm="records.search"
            :fullWidthSearch="false"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>
          <v-btn
            v-if="!records.loading"
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="showAddRecordModal"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus
            </v-icon>
            {{ $vuetify.breakpoint.mobile ? "" : $t('button.dns.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-dns-table
              :checkedItems="checkedItems"
              :headers="records.headers"
              :items="records.items"
              :itemsLoading="records.loading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="records.itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              @toggle-proxy-status="toggleProxyStatus"
              :itemClass="itemClass"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.dns.title')"
                  :desc="$t('message.emptyTable.dns.description')"
                >
                  <template v-slot:image>
                    <hosting-dns-illustration />
                  </template>
                </TableFullScreenMessage>
              </template>

              <template v-slot:actions="item">
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showEditRecordModal(item)"
                    >
                      <v-icon>$edit2</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.dns.edit') }}</span>
                </v-tooltip>

                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showDeleteRecordModal(item)"
                    >
                      <v-icon>$thrash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.dns.delete') }}</span>
                </v-tooltip>
              </template>

              <template v-slot:mobile="{ item, headers }">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.name') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.name }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.ttl') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.ttl }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.type') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.type }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.record') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.content }}</span>
                  </div>
                </div>
              </template>
            </hosting-dns-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingDnsTable from "../../components/dataIterator/tables/HostingDnsTable.vue";
import HostingDnsIllustration from "../../components/illustrations/hosting/hosting-dns-illustration.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
    BasicModal,
    DataIteratorFilterControls,
    HostingDnsTable,
    HostingDnsIllustration,
    TableFullScreenMessage,
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin],
  data() {
    return {
      dnsInstance: this.$route.params.instance,
      isServerProvided: false,
      zone: {},
      itemsPerPage: 8,
      searchTerm: "",
      sortBy: "",
      sortDesc: false,
      zones: {
        loading: true,
        items: [],
      },
      records: {
        loading: true,
        items: [],
        headers: [
          {
            text: this.$t('table.header.name'),
            value: "name",
            sortable: true,
          },
          {
            text: this.$t('table.header.ttl'),
            value: "ttl",
            sortable: true,
          },
          {
            text: this.$t('table.header.type'),
            value: "type",
            sortable: true,
          },
          {
            text: this.$t('table.header.value'),
            value: "content",
            sortable: true,
          },
          {
            text: this.$t('table.header.actions'),
            value: "actions",
          },
        ],
      },
      recordTypes: {
        loading: false,
        items: []
      },
      modalRender: 0,
      modalOptions: { open: false },
      page: 1,
      highlightItem: {},
      serverAccount: null
    };
  },
  props: {
    service: Object
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function () {
      this.zones.items = [];
      this.records.loading = true;
      this.reloadZones();
      this.changeHeaders();
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  methods: {
    passInstanceData() {
      if(this.dnsInstance){
        this.$emit('pass-instance', this.dnsInstance);
      }
    },
    formFields(selectedType = "A", edit = false) {
      let fields = [
        {
          label: this.$t('form.label.type'),
          name: "type",
          tooltip: "tooltip.add_dns_record.type",
          type: "select",
          readonly: edit == true,
          rules: [],
          options: this.recordTypes.items,
          onChange: (values) => {
            switch (values.type) {
              case "A":
              case "AAAA":
                this.$refs.modal.showFields(["address", "proxied"]);
                this.$refs.modal.hideFields([
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "preference",
                  "exchange",
                  "nameserver",
                  "pointer",
                  "rdata"
                ]);
                break;
              case "CAA":
                this.$refs.modal.showFields(["flag", "tag", "value"]);
                this.$refs.modal.hideFields([
                  "address",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "preference",
                  "exchange",
                  "proxied",
                  "nameserver",
                  "pointer",
                  "rdata"
                ]);
                break;
              case "CNAME":
                this.$refs.modal.showFields(["cname", "proxied"]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "preference",
                  "exchange",
                  "nameserver",
                  "pointer",
                  "rdata"
                ]);
                break;
              case "SRV":
                this.$refs.modal.showFields([
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "proxied",
                  "nameserver",
                  "pointer",
                  "rdata"
                ]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "txtdata",
                  "preference",
                  "exchange",
                  "proxied",
                  "nameserver",
                  "pointer",
                  "rdata"
                ]);
                break;
              case "TXT":
                this.$refs.modal.showFields(["txtdata"]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "preference",
                  "exchange",
                  "proxied",
                  "nameserver",
                  "pointer",
                  "rdata"
                ]);
                break;
              case "MX":
                this.$refs.modal.showFields(["preference", "exchange"]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "proxied",
                  "rdata",
                  "nameserver",
                  "pointer"
                ]);
                break;
              case "NS":
                this.$refs.modal.showFields(["nameserver"]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "proxied",
                  "preference",
                  "exchange",
                  "rdata",
                  "pointer"
                ]);
                break;
              case "PTR":
                this.$refs.modal.showFields(["pointer"]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "proxied",
                  "preference",
                  "exchange",
                  "nameserver",
                  "rdata"
                ]);
                break;
              default:
                this.$refs.modal.showFields(["rdata"]);
                this.$refs.modal.hideFields([
                  "address",
                  "flag",
                  "tag",
                  "value",
                  "cname",
                  "target",
                  "weight",
                  "port",
                  "priority",
                  "txtdata",
                  "proxied",
                  "preference",
                  "exchange",
                  "pointer"
                ]);
            }
          },
        },
        {
          label: this.$t('form.label.name'),
          name: "name",
          tooltip: "tooltip.add_dns_record.name",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.name')}),
            (v) =>
              (v && v.length <= 255) ||
                this.$t('form.validation.maxLength', {
                  field: this.$t('form.label.name'),
                  length: 255
                }),
          ],
        },
        {
          label: this.$t('form.label.ttl'),
          tooltip: "tooltip.add_dns_record.ttl",
          name: "ttl",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.ttl')}),
            (v) => v >= 0 ||
              this.$t('form.validation.gt', {
                field: this.$t('form.label.ttl'),
                number: 0
              }),
          ],
          type: "text",
        },
        {
          label: this.$t('form.label.address'),
          name: "address",
          tooltip: "tooltip.add_dns_record.address",
          hidden: selectedType != "A" && selectedType != "AAAA",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.address')}),
            (v) =>
              (v && v.length <= 255) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.address'),
                length: 255
              }),
          ],
          type: "text",
        },
        {
          label: this.$t('form.label.tag'),
          name: "tag",
          tooltip: "tooltip.add_dns_record.tag",
          hidden: selectedType != "CAA",
          type: "select",
          rules: [],
          options: [
            {
              label: this.$t('form.option.issue'),
              value: "issue",
            },
            {
              label: this.$t('form.option.issuewild'),
              value: "issuewild",
            },
            {
              label: this.$t('form.option.iodef'),
              value: "iodef",
            },
          ],
        },
        {
          label: this.$t('form.label.value'),
          name: "value",
          tooltip: "tooltip.add_dns_record.value",
          hidden: selectedType != "CAA",
          rules: [],
          type: "text",
        },
        {
          label: this.$t('form.label.flag'),
          name: "flag",
          tooltip: "tooltip.add_dns_record.flag",
          hidden: selectedType != "CAA",
          rules: [],
          type: "checkbox",
        },
        {
          label: this.$t('form.label.cname'),
          name: "cname",
          tooltip: "tooltip.add_dns_record.cname",
          hidden: selectedType != "CNAME",
          rules: [],
          type: "text",
        },
        {
          label: this.$t('form.label.target'),
          name: "target",
          tooltip: "tooltip.add_dns_record.target",
          hidden: selectedType != "SRV",
          rules: [],
          type: "text",
        },
        {
          label: this.$t('form.label.weight'),
          name: "weight",
          tooltip: "tooltip.add_dns_record.weight",
          hidden: selectedType != "SRV",
          rules: [],

          type: "text",
        },
        {
          label: this.$t('form.label.port'),
          name: "port",
          tooltip: "tooltip.add_dns_record.port",
          hidden: selectedType != "SRV",
          rules: [],

          type: "text",
        },
        {
          label: this.$t('form.label.priority'),
          name: "priority",
          tooltip: "tooltip.add_dns_record.priority",
          hidden: selectedType != "SRV",
          rules: [],

          type: "text",
        },
        {
          label: this.$t('form.label.txt'),
          name: "txtdata",
          tooltip: "tooltip.add_dns_record.txtdata",
          hidden: selectedType != "TXT",
          rules: [],

          type: "textarea",
        },
        {
          label: this.$t('form.label.priority'),
          name: "preference",
          tooltip: "tooltip.add_dns_record.preference",
          hidden: selectedType != "MX",
          rules: [],

          type: "text",
        },
        {
          label: this.$t('form.label.destination'),
          name: "exchange",
          tooltip: "tooltip.add_dns_record.exchange",
          hidden: selectedType != "MX",
          rules: [],

          type: "text",
        },
        {
          label: this.$t('form.label.nameserver'),
          name: "nameserver",
          tooltip: "tooltip.add_dns_record.nameserver",
          hidden: selectedType != "NS",
          rules: [],
          type: "text",
        },
        {
          label: this.$t('form.label.pointer'),
          name: "pointer",
          tooltip: "tooltip.add_dns_record.pointer",
          hidden: selectedType != "PTR",
          rules: [],
          type: "text",
        },
        {
          label: this.$t('form.label.rdata'),
          name: "rdata",
          tooltip: "tooltip.add_dns_record.rdata",
          hidden: selectedType === "A"
            || selectedType === "AAAA"
            || selectedType === "CAA"
            || selectedType === "CNAME"
            || selectedType === "MX"
            || selectedType === "SRV"
            || selectedType === "TXT"
            || selectedType === "NS"
            || selectedType === "PTR"
          ,
          rules: [],

          type: "text",
        },
      ];

      if (this.serverAccount.service_dns_server_cloudflare) {
        fields.push({
          label: this.$t('form.label.proxy'),
          name: "proxied",
          tooltip: "tooltip.add_dns_record.proxy",
          hidden:
            selectedType != "A" &&
            selectedType != "AAAA" &&
            selectedType != "CNAME",
          type: "switch",
        });
      }
      return fields;
    },
    showAddRecordModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.dns.modal.add.title');
      this.modalOptions.icon = "$plus";
      this.modalOptions.message = this.$t('heading.hosting.dns.modal.update.message', {zone: `<strong>${this.zone.name}</strong>`});
      this.modalOptions.buttons.unshift({
        label: this.$t('button.dns.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addDnsRecord(modal.formValues);
        },
      });
      this.modalOptions.formFields = this.formFields("A");
      this.modalOptions.item = { type: "A", ttl: "14400", tag: "issue" };
      this.modalOptions.open = true;
    },
    addDnsRecord(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(
        `/server-accounts/${this.serverAccount.id}/dns-zones/${this.zone.name}/records`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.dns.add.success');
          this.highlightItem = {
            type: formData.type,
            name: formData.name.trim(),
          };
          this.reloadZoneRecords(this.zone);

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showEditRecordModal(item) {
      this.resetModal();
      if (
        item.type == "TXT" &&
        item.txtdata &&
        item.txtdata.length >= 2 &&
        item.txtdata.charAt(0) == '"' &&
        item.txtdata.charAt(item.txtdata.length - 1) == '"'
      ) {
        item.txtdata = item.txtdata.substring(1, item.txtdata.length - 1);
      }
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.dns.modal.update.title');
      this.modalOptions.icon = "$edit";
      this.modalOptions.message = this.$t('heading.hosting.dns.modal.update.message', {zone: `<strong>${this.zone.name}</strong>`});
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateDnsRecord(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = this.formFields(item.type, true);
      this.modalOptions.open = true;
    },
    updateDnsRecord(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      let dnsRecord = item.line === undefined ? item.Line : item.line;
      if (dnsRecord === undefined) {
        dnsRecord = item.name;
      }

      Api.put(
        `/server-accounts/${this.serverAccount.id}/dns-zones/${this.zone.name}/records/${dnsRecord}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.dns.update.success');
          this.highlightItem = { type: item.type, name: formData.name };
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.reloadZoneRecords(this.zone);
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteRecordModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.dns.modal.delete.title');
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.dns'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];

      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteDnsRecord(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteDnsRecord(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      let dnsRecord = item.line === undefined ? item.Line : item.line;
      if (dnsRecord === undefined) {
        dnsRecord = item.name + "?type=" + item.type;
      }

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/dns-zones/${this.zone.name}/records/${dnsRecord}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.dns.delete.success');
          this.reloadZoneRecords(this.zone);

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    reloadZoneRecords(zone = null) {
      if (zone !== null) {
        this.zone = zone;
      }
      this.records.loading = true;
      if (this.zone === null) {
        this.records.loading = false;
        return;
      }
      Api.get(
        `/server-accounts/${this.serverAccount.id}/dns-zones/${this.zone.name}/records`
      )
        .then((response) => {
          this.records.items = response.data.data.map((record) => {
            record.ttl = record.ttl === 1 ? "Auto" : record.ttl;
            record.loading = false;
            return record;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.records.loading = false;
        });
    },
    reloadZones() {
      this.zones.loading = true;
      Api.get(`/server-accounts/${this.serverAccount.id}/dns-zones`)
        .then((response) => {
          this.zones.items = response.data.data;
          if (response.data.data.length) {
            this.zone = response.data.data[0];
            this.reloadZoneRecords();
            this.reloadRecordTypes();
          } else {
            this.records.items = [];
            this.records.loading = false;
          }
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.zones.loading = false;
        });
    },
    toggleProxyStatus(record) {
      record.loading = true;
      Api.patch(
        `/server-accounts/${this.serverAccount.id}/dns-zones/${this.zone.name}/records/${record.line}/toggle-proxy`
      )
        .then((response) => {
          record.proxied = response.data.data.proxied;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          record.loading = false;
        });
    },
    itemClass(item) {
      if (
        this.highlightItem.name &&
        item.name == this.highlightItem.name &&
        item.type == this.highlightItem.type
      ) {
        return "highlight";
      }
    },
    changeHeaders() {
      const proxiableHeader = {
        text: this.$t('form.label.proxy'),
        value: "proxied",
        sortable: false,
      };

      if (this.service.dns_server_type === 'cloudflare') {
        this.records.headers.splice(4, 0, proxiableHeader);
      } else {
        this.records.headers = this.records.headers.filter(
          (header) => header.value !== "proxied"
        );
      }
    },
    reloadRecordTypes() {
      this.recordTypes.loading = true;
      Api.get(
        `/server-accounts/${this.serverAccount.id}/dns-zones/record-types`
      )
        .then((response) => {
          this.recordTypes.items = response.data.data.map(type => {
            return {
              label: type,
              value: type
            }
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.recordTypes.loading = false;
        });
    }
  },
  mounted() {
    this.passInstanceData();
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
      this.reloadZones();
      this.reloadRecordTypes();
      this.changeHeaders();
    }
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}

.page-title__filter-controls {
  max-width: fit-content;
}

.v-input.v-select::v-deep {
  fieldset {
    border: none;
  }

  .v-select__selection {
    padding: 0;
    font-size: "$font-size-h3";
    line-height: "$line-height-h3";
    font-weight: "$font-weight-bold";
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
    @media (min-width: 1401px) {
      max-width: 99%;
    }
  }

  &.v-select .v-select__slot {
    width: unset;
  }
}

.text-gray {
  color: map-get($gray, darken1);
}
</style>
