<template>
  <v-select
    class="dns-zone"
    outlined
    :hide-details="true"
    :value="valueData"
    v-model="valueData"
    :items="items"
    item-text="domain"
    item-value="id"
    return-object
    :menu-props="{
      offsetY: true,
      nudgeBottom: '8px',
    }"
  />
</template>

<script>
export default {
  name: "ActiveServerAccountSelector",
  props: {
    items: Array,
    value: Object
  },
  computed: {
    valueData: {
      get: function () {
        return this.value;
      },
      set: function (newData) {
        this.$emit("update:value", newData);
      },
    },
  },
}
</script>