<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.69444 41.4828C-3.99291 33.1786 9.47984 6.87382 28.6214 3.54221C50.0944 -0.212063 65.1623 16.0979 61.4321 34.8942C57.3339 55.6297 30.3147 65.9975 22.6336 58.6381C18.1181 54.312 25.652 49.0162 20.0813 43.6956C14.5106 38.375 8.74362 45.3862 4.69444 41.5077V41.4828Z"
      fill="#F9F9FB"
    />
    <path
      d="M32 53C43.598 53 53 43.598 53 32C53 20.402 43.598 11 32 11C20.402 11 11 20.402 11 32C11 43.598 20.402 53 32 53Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.9997 53C38.6463 53 44.0345 43.598 44.0345 32C44.0345 20.402 38.6463 11 31.9997 11C25.3531 11 19.9649 20.402 19.9649 32C19.9649 43.598 25.3531 53 31.9997 53Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.4626 32H52.9998"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32 11.3167V52.7564"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.7737 46.471C20.5985 42.4513 26.0069 39.942 31.9999 39.942C37.9929 39.942 43.4013 42.4513 47.2261 46.471"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.2261 17.529C43.4013 21.5487 37.9929 24.058 31.9999 24.058C26.0069 24.058 20.5985 21.5487 16.7737 17.529"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.0134 59V53"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M45 56.0933L51 55.88"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M10 10L4 4"
      stroke="#797997"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 10L10 4"
      stroke="#797997"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59 49C60.6569 49 62 47.6569 62 46C62 44.3431 60.6569 43 59 43C57.3431 43 56 44.3431 56 46C56 47.6569 57.3431 49 59 49Z"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>